<template>
<div>
  <a-radio-group v-model="formType" default-value="income" @change="handleTypeChange">
    <a-radio-button value="expense">
      Расход
    </a-radio-button>
    <a-radio-button value="overhead">
      Расход по накладной
    </a-radio-button>
  </a-radio-group>
  <h2>Расход продуктов</h2>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item label="Наименование склада">
      <a-select
        @change="selectWarehouse"
        placeholder="Склад"
        v-decorator="[
          'WarehouseId',
          { rules: [{ required: true,
          message: 'Пожалуйста выберите Склад' }] }, ]">
        <a-select-option
          v-for="warehouse in warehouses"
          :key="warehouse.Name"
          :value="warehouse.Id">
          {{ warehouse.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-if="formItems.toWarehouse" label="Целевой склад">
      <a-select
        @change="selectToWarehouseId"
        placeholder="Склад"
        :disabled="!WarehouseId"
        v-decorator="[
          'ToWarehouseId',
          { rules: [{ required: true,
          message: 'Пожалуйста Целевой Склад' }] }, ]">
        <a-select-option
          v-for="warehouse in toWarehouses"
          :key="warehouse.Name"
          :value="warehouse.Id">
          {{ warehouse.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Поиск по атрибуту">
      <a-input placeholder="RED"
               ref='focused'
               v-model="searchAttribute"
               v-on:keyup.enter="searchBar" style="width: 20%; margin: 1em 0" />
    </a-form-item>

    <a-row>
      <a-col :span="12">
        <a-form-item label="Товар">
          <a-select
            :value="searchProductValue"
            placeholder="Введите наименование товара"
            style="width: 400px"
            :default-active-first-option="false"
            :show-arrow="false"
            :showSearch="true"
            :filterOption="filterProduct"
            :not-found-content="null"
            @change="handleChange"
          >
            <a-select-option v-for="(product, index) in suggestionProducts" :key="index">
              {{ product.Product.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item label="Комментарий к расходу">
      <a-textarea
        v-model="comment"
        placeholder="Напишите комментарий к расходу"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        :maxLength="1500"
        @input="editCommentLength()"
      />
      <p style="text-align: end">{{ commentLength }}</p>
    </a-form-item>
    <a-form-item label="Выбранные товары">
      <div class="ant-form-explain" v-if="!selectedProducts.length"
           style="color: #f5222d">Пожалуйста добавьте товары</div>
      <a-table bordered :data-source="selectedProducts" :columns="columns">
        <template slot="name" slot-scope="text, record">
          <editable-cell text="text" @change="onCellChange(record.key, 'name', $event)" />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="selectedProducts.length"
            title="Sure to delete?"
            @confirm="() => onDelete(record.key)"
          >
            <a href="javascript:;">Delete</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-form-item>

    <a-form-item>
      <a-button v-if="formType === 'overhead'" type="secondary" @click="createOverhead">
        Создать накладной
      </a-button>
      <a-button v-else type="primary" @click="onSubmit">
        Расходовать
      </a-button>
    </a-form-item>
  </a-form>
  <a-modal
    title="Выберите один из товаров"
    :visible="modalVisible"
    @cancel="closeModal"
  >
    <a-table :columns="modalColumns" :data-source="products" size="small">
      <template slot="select" slot-scope="select">
        <a-button v-on:click="selectProduct(select)">Добавить</a-button>
      </template>
    </a-table>
    <template slot="footer"><div></div></template>
  </a-modal>
</div>
</template>
<script>
import EditableCell from '../../../components/EditableCell.vue';

const modalColumns = [
  {
    title: 'Товар',
    dataIndex: 'Product.Name',
    key: 'Name',
    scopedSlots: { customRender: 'Product.Name' },
  },
  {
    title: 'Цена',
    key: 'Product.Price',
    dataIndex: 'Product.Price',
  },
  {
    title: '',
    scopedSlots: { customRender: 'select' },
  },
];

export default {
  components: { EditableCell },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'create' });
  },
  data() {
    return {
      formType: 'expense',
      formItems: {
        warehouse: true,
        toWarehouse: false,
      },
      modalColumns,
      comment: '',
      commentLength: 1500,
      warehouses: [],
      toWarehouses: [],
      WarehouseId: false,
      toWarehouseId: false,
      dataSource: [],
      products: [],
      suggestionProducts: [],
      amountValue: 1,
      priceValue: 1,
      searchProductValue: undefined,
      searchProduct: undefined,
      selectedProducts: [],
      loading: false,
      modalVisible: false,
      selectedProductAttributes: [],
      searchAttribute: '',
      columns: [
        {
          title: 'Наименование товара',
          dataIndex: 'Name',
          width: '30%',
          scopedSlots: { customRender: 'Name' },
        },
        {
          title: 'Количество',
          dataIndex: 'Amount',
        },
        {
          title: 'Сумма',
          dataIndex: 'Price',
        },
        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      isSelected: false,
    };
  },
  mounted() {
    this.$refs.focused.focus();
  },
  created() {
    this.fetchWarehouses();
  },
  methods: {
    fetchWarehouses() {
      this.$api.getWarehousesList(false, ({ data }) => {
        this.warehouses = data.data;
      });
    },
    createOverhead(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.loading = true;
          this.selectedProducts.map((item) => {
            delete item.Name;
            return item;
          });
          this.$api.overheadOutputStore({
            WarehouseId: this.WarehouseId,
            Products: this.selectedProducts,
            ToWarehouseId: this.toWarehouseId,
            Comment: this.comment,
          }, (response) => {
            const { data } = response.data;
            this.$router.push({ name: 'income-details', params: { invoiceId: data.Id } });
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        }
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.loading = true;
          this.selectedProducts.map((item) => {
            delete item.Name;
            return item;
          });
          this.$api.outputStore({
            WarehouseId: this.WarehouseId,
            Comment: this.comment,
            Products: this.selectedProducts,
          }, () => {
            this.$router.push({ name: 'invoices-expense-list' });
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        }
      });
    },
    selectWarehouse(value) {
      this.WarehouseId = value;
      this.toWarehouses = this.warehouses.filter((item) => item.Id !== this.WarehouseId);
      this.$api.getStorageProducts({
        warehouseId: this.WarehouseId,
      }, '', (response) => {
        const { data } = response.data;
        this.suggestionProducts = data;
      });
    },
    selectToWarehouseId(value) {
      this.toWarehouseId = value;
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(key) {
      const dataSource = [...this.selectedProducts];
      this.selectedProducts = dataSource.filter((item) => item.key !== key);
    },
    handleAdd() {
      const product = this.searchProduct;

      const exists = this.selectedProducts.find((x) => x.Id === product.Id);

      if (exists) {
        exists.Price = (this.amountValue * this.priceValue) + exists.Price;
        exists.Amount = this.amountValue + exists.Amount;
      } else {
        this.selectedProducts.push({
          ProductId: product.Id,
          Name: product.Name,
          Amount: this.amountValue,
          Price: this.priceValue,
          ProductWarehouseIds: [product.ProductWarehouseId],
        });
      }
      this.searchProduct = undefined;
      this.searchProductValue = undefined;
      this.priceValue = 1;
    },
    handleChange(value) {
      const { Name } = this.suggestionProducts[value];
      this.searchProduct = this.suggestionProducts[value];
      this.searchProductValue = Name;
      const product = this.suggestionProducts[value];
      this.products = product.ProductWarehouses.map((element) => ({
        Amount: product.Product.Amount,
        Product: {
          Id: product.Product.Id,
          Name: product.Product.Name,
          Price: product.Product.Price,
          Amount: product.Product.Amount,
        },
        ProductWarehouseId: element.ProductWarehouseId,
      }));
      this.modalVisible = true;
    },
    selectAttribute(index, attribute) {
      const attributeId = this.searchProduct.Attributes[attribute].Id;
      const value = this.searchProduct.Attributes[attribute].Values[index];
      const attributeKeys = Object.keys(this.searchProduct.Attributes);
      const keys = Object.entries(attributeKeys).reduce((ret, entry) => {
        const { 0: entryKey, 1: entryValue } = entry;
        ret[entryValue] = entryKey;
        return ret;
      }, {});
      this.selectedProductAttributes[keys[attribute]] = {
        AttributeId: attributeId,
        Name: attribute,
        Type: '',
        Value: value,
      };
    },
    amountOnChange() {

    },
    priceOnChange() {

    },
    searchBar(event) {
      if (!this.WarehouseId) {
        this.$notification.warning({
          message: 'Выберте склад',
        });
        return false;
      }
      const { value } = event.target;
      const filters = {
        WarehouseId: this.WarehouseId,
        Value: value,
      };
      const query = Object.keys(filters)
        .map((key) => filters[key] && `filter[${key}]=${filters[key]}`)
        .join('&');
      this.$api.filterStorageProducts(query, (response) => {
        const { data } = response.data;
        if (data.length === 0) {
          this.$notification.warning({
            message: `Товар с атрибутом "${value}" не найден.`,
          });
        }
        if (data.length > 1) {
          this.modalVisible = true;
          this.products = data;
        } else if (data.length === 1) {
          const { 0: product } = data;
          this.selectedProducts.map((selectedItem) => {
            if (selectedItem.ProductId === product.Product.Id) {
              this.isSelected = true;
              selectedItem.ProductWarehouseIds.push(product.ProductWarehouseId);
              selectedItem.Amount += 1;
            }
            return selectedItem;
          });
          if (!this.isSelected) {
            this.selectedProducts.push({
              Name: product.Product.Name,
              ProductId: product.Product.Id,
              Amount: 1,
              Price: product.Product.Price,
              ProductWarehouseIds: [product.ProductWarehouseId],
            });
          }
          this.isSelected = false;
        }
      });
      this.searchAttribute = '';
      return true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    selectProduct(item) {
      this.modalVisible = false;
      this.selectedProducts.map((selectedItem) => {
        if (selectedItem.ProductId === item.Product.Id) {
          this.isSelected = true;
          selectedItem.ProductWarehouseIds.push(item.ProductWarehouseId);
          selectedItem.Amount += 1;
        }
        return selectedItem;
      });
      if (!this.isSelected) {
        this.selectedProducts.push({
          Name: item.Product.Name,
          ProductId: item.Product.Id,
          Amount: 1,
          Price: item.Product.Price,
          ProductWarehouseIds: typeof item.ProductWarehouses === 'object'
            ? item.ProductWarehouses.map((warehouse) => warehouse.ProductWarehouseId)
            : [item.ProductWarehouseId],
        });
      }
      this.isSelected = false;
    },
    handleTypeChange(event) {
      if (event.target.value === 'overhead') {
        this.formItems.toWarehouse = true;
      } else {
        this.selectedProducts = [];
        this.formItems.toWarehouse = false;
      }
    },
    editCommentLength() {
      this.commentLength = 1500 - this.comment.length;
    },
    filterProduct(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
